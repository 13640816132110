import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
// import LinkButton from "../components/LinkButton";
import LogoCloud from "../components/LogoCloud";
// import MotionSvg from "../components/icons/MotionSvg";

const CustomDistributedMultimediaPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Custom Distributed Multimedia Apps" />
          <Card
            visible={cardVisible}
            header="Custom Distributed Multimedia Apps"
            nextUrl="/custom-cms"
            nextLabel="Explore features"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-3">
              Multitouch devices have become a pervasive technology.
              Simultaneously, platforms to develop native desktop applications
              have become more and more accessible. This leads to a fantastic
              concurrence of opportunities for the development of highly
              customized software, fast and inexpensive.
            </p>

            <p className="text-base leading-6 text-gray-700 text-left mb-3">
              Together with my partners I have years of experience in the
              development of
            </p>
            <ul className="list-inside list-disc text-base leading-6 text-gray-700 text-left mb-3">
              <li>museum and trade fair exhibits,</li>
              <li>interactive installations,</li>
              <li>digital signage</li>
            </ul>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              { name: "zone", url: "https://zonemedia.at/" },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default CustomDistributedMultimediaPage;
